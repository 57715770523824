import React from "react";
import "../config/i18n";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/career/Hero";
import Features from "../sections/career/Features";
import Jobs from "../sections/career/Jobs";
import { Link } from 'gatsby';
import { useTranslation } from "react-i18next";

const PricingPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <><Link to="https://profile.thedigi.app">
            <button className="btn btn btn-dark-digi btn-sm-2 rounded-8 h-50 font-size-5">
            <i className="fa fa-lock mr-9 font-size-5 " />   {t("login")}  
          </button>
          </Link>
            </>
          ),
          footerStyle: "style6",
        }}
      >
        <Hero />
        <Features />
        <Jobs />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
