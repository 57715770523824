import React from "react";

import { useTranslation } from "react-i18next";

const Hero = () => {
  
const { t, i18n } = useTranslation();
  return (
    <div className="pt-23 pt-md-25 pt-lg-30">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-11 mb-lg-24"
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h2 className="font-size-11 mb-5">
              {t("careers_title")}
              </h2>
              <p className="font-size-7 mb-0">
              {t("careers_subtitle")}
              </p>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
